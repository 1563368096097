.fa-2x {
    font-size: 2em;
}
.fa {
    position: relative;
    display: table-cell;
    width: 60px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    font-size:20px;
}
.main-menu svg {
    position: relative;
    display: table-cell;
    height: 20px;
    width: 60px;
    margin: 10px 0px;
    text-align: center;
    vertical-align: middle;
}
.main-menu {
    background:#ffffff;
    border-right:1px solid #e5e5e5;
    position:absolute;
    top:0;
    bottom:0;
    height:100%;
    left:0;
    width:60px;
    overflow:hidden;
    -webkit-transition:width .05s linear;
    transition:width .05s linear;
    -webkit-transform:translateZ(0) scale(1,1);
    z-index:1000;
}
.main-menu li {
    position:relative;
    display:block;
    width:250px;
}
.main-menu li > a {
    position:relative;
    display:table;
    border-collapse:collapse;
    border-spacing:0;
    color:hsla(0,0%,0%,1);
    font-family: arial;
    font-size: 15px;
    text-decoration:none;
    -webkit-transform:translateZ(0) scale(1,1);
    -webkit-transition:all .1s linear;
    transition:all .1s linear;
}
.main-menu li svg path, .main-menu li svg {
    color: hsla(0,0%,0%,.75);
    fill: hsla(0,0%,0%,.75);
}
.main-menu .nav-icon {
    position:relative;
    display:table-cell;
    width:60px;
    height:36px;
    text-align:center;
    vertical-align:middle;
    font-size:18px;
}
.main-menu .nav-text {
    position:relative;
    display:table-cell;
    vertical-align:middle;
    width:190px;
}
.main-menu > ul.logout {
    position:absolute;
    left:0;
    bottom:0;
}
.no-touch .scrollable.hover {
    overflow-y:hidden;
}
.no-touch .scrollable.hover:hover {
    overflow-y:auto;
    overflow:visible;
}
a:hover,a:focus {
    text-decoration:none;
}
nav {
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
}

nav ul, nav li {
    outline:0;
    margin:0;
    padding:0;
}
.main-menu li:hover>a,
nav.main-menu li.active>a,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus,
.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
    color:hsla(0,0%,0%,.75);
    background-color: hsla(0,0%,0%,.07);
}
.area {
    min-width: 60px;
}
@media only screen and (max-width: 767px)
{
    .main-menu {
        width: 0px;
    }
    .area {
        min-width: 0px;
    }
    nav.main-menu.expanded {
        width:250px;
        overflow:visible;
    }
}

@media only screen and (min-width: 768px)
{
    .main-menu:hover,
    nav.main-menu.expanded {
        width:250px;
        overflow:visible;
    }
}