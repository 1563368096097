.Scroller-scroller {
  scrollbar-color: hsla(0,0%,0%,.2) transparent;
  -webkit-overflow-scrolling: touch;
}
.Scroller-none {
  overflow-x: hidden;
  overflow-y: hidden;
}
.Scroller-vertical {
  overflow-x: hidden;
  overflow-y: scroll;
}
.Scroller-scroller::-webkit-scrollbar {
  width: 14px;
}

.Scroller-scroller::-webkit-scrollbar-thumb {
  min-height: 50px;
  border: 3px solid transparent;
  border-radius: 8px;
  background-color: hsla(0,0%,0%,.2);
  background-clip: padding-box;
}
.Scroller-scroller::-webkit-scrollbar-track {
  background-color: transparent;
}
.FullPage-container {
  font-size: 13px;
  font-family: Open Sans Semibold,Helvetica Neue,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.FullPage-container {
  font-size: 14px;
}
.NavBar-container {
  font-size: 13px;
  font-family: Open Sans Semibold,Helvetica Neue,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  /* z-index: 99; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  height: 60px;
  background: #4CAF50;
}
.Link-link {
    margin: 0;
    padding: 0;
    outline: none;
    border: 0;
    border-radius: 0;
    background: none;
    text-align: inherit;
    text-decoration: none;
    cursor: pointer;
    transition: color .2s;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
}
.Link-default {
    color: hsla(0,0%,100%,.7);
}
.Link-light {
  color: hsla(0,0%,0%,.7);
}
.Link-link:hover {
    color: #fff;
}
.Link-light:hover {
  color: hsla(0,0%,0%,.2);
}
.NavBar-left {
    padding-left: 10px;
}
.NavBar-right {
  padding-right: 17px;
}
.NavBar-side {
    display: flex;
    align-items: center;
    height: 100%;
}
.NavBarIconButton-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  font-size: 20px;
}

.NavBarIconButton-button .NavBarIconButton-icon {
  top: 0;
}

.NavBar-right .NavBar-button {
  padding: 0 15px;
}

.NavBar-pahLogo {
  text-indent: -9999px;
  margin: 0 10px;
  width: 45px;
  background-image: url(../img/plan-a-head-logo.svg);
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
}
.PahIcon-pahIcon {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  line-height: 1;
  fill: currentColor;
}
.QuickSearch-container {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  width: 360px;
  height: 40px;
  vertical-align: super;
}
.QuickSearchInput-container {
  font-size: 13px;
  font-family: Open Sans Semibold,Helvetica Neue,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  padding: 4px;
  height: 40px;
  border-radius: 4px;
  background: hsla(0,0%,100%,.08);
  color: #eee;
  transition: background-color .2s,color .2s;
}
.QuickSearchInput-searchInput {
  flex-grow: 1;
  height: 100%;
  outline: 0;
  border: none;
  background: transparent;
  color: #eee;
}
.QuickSearchInput-closeIcon, .QuickSearchInput-searchIcon {
  margin: 4px 10px 0 18px;
  font-size: 14px;
}
.SettingsButton-button {

}
.ToolbarButton-toolbarButton {
  display: inline-block;
  flex-shrink: 0;
  width: 50px;
  height: 100%;
  text-align: center;
  font-size: 20px;
}
.ButtonLarge {
  font-size: 30px;
}
.NavBarAccountButton-avatarContainer {
  display: flex;
}
.DisclosureArrowButton-disclosureArrowButton {
  overflow: hidden!important;
  min-width: 0;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap!important;
  position: relative;
  padding-right: 13px;
  min-width: 30px;
}
.NavBarAccountButton-button {
  position: relative;
  overflow: visible!important;
  height: 100%;
  -webkit-transform: translateZ(0);
}
.NavBar-accountButton {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 25px;
}
.NavBarAccountButton-overlay {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 50%;
  opacity: 0;
  transition: opacity .2s;
}
.AvatarImg-avatar {
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  background-image: url(../img/plan-a-head-logo.svg);
  background-size: contain;
}
.DisclosureArrowButton-disclosureArrowButton.DisclosureArrowButton-medium {
  padding-right: 13px;
}
.PageHeaderBadge-badge {
  line-height: 1.5;
}
.Badge-badge {
  padding: 0 8px;
  border-radius: 4px;
  white-space: nowrap;
  transition: all .2s;
}
.Badge-isHidden {
  opacity: 0;
  transform: scale(.5);
}
.Badge-primary {
  background-color: #4CAF50;
}
.Badge-default {
  background-color: rgba(0,0,0,.15);
}
.Menu-menu {
  display: block;
  position: absolute;
  top: 50px;
  font-size: 13px;
  font-family: Open Sans Semibold,Helvetica Neue,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0,0,0,.35);
  transition: background .15s ease-out;
  transform-origin: center top;
  z-index: 1001;
}
.Menu-medium {
  width: 180px;
}
.MenuItem-menuItem {
  font-size: 13px;
  font-family: Open Sans Semibold,Helvetica Neue,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  padding: 6px 20px;
  width: 100%;
  text-align: left;
  word-wrap: break-word;
  border-bottom: 1px solid #dddddd;
}
.MenuItem-menuItem:hover {
  background-color: #edebe9;
  cursor: pointer;
}
.Menu-menu li:last-child {
  border:none
}
.NavBarAccountButton-badge {
  position: absolute;
  top: 27px;
  left: -15px;
  font-size: 11px;
}
.Page-page {
  font-size: 13px;
  font-family: Open Sans Semibold,Helvetica Neue,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}
.PageHeader-pageHeader {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding-right: 25px;
  padding-left: 20px;
  height: 50px;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,.15);
  color: hsla(0,0%,0%,.75);
  background: #fff;
  font-size: 16px;
}
.PageFooter-pageFooter {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.4);
  border-top: #eee solid 1px;
  /* padding-right: 25px;
  padding-left: 20px; */
  /* height: 50px;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,.15);
  color: hsla(0,0%,0%,.75);
  background: #fff;
  font-size: 16px; */
}
.PageHeaderCenter-pageHeaderCenter {
  display: flex;
  align-items: center;
  flex: 1 1 25%;
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
  justify-content: center;
  flex-basis: 50%;
}
.PageHeaderLeft-pageHeaderLeft, .PageHeaderRight-pageHeaderRight {
  display: flex;
  align-items: center;
  flex: 1 1 25%;
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
}
.PageHeaderLeft-pageHeaderLeft {
  justify-content: flex-start;
}
.PageHeaderRight-pageHeaderRight {
  justify-content: flex-end;
}
.PageHeaderCenter-pageHeaderCenter {
  position: relative;
}
.PageHeader-pageHeader .PahIcon-pahIcon, .PageHeader-pageHeader .QuickSearchInput-searchInput {
  color: hsla(0,0%,0%,.75);
  fill: hsla(0,0%,0%,.75);
} 
.PageHeaderTitle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 200px;
}
.PageHeaderTitle-title {
  color: hsla(0,0%,0%,.75);
  font-size: 15px;
  line-height: 1.5;
}
.PageHeaderTitle-detail, .PageHeaderTitle-title {
  overflow: hidden!important;
  min-width: 0;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap!important;
  align-self: flex-start;
  font-family: Open Sans Semibold,Helvetica Neue,Helvetica,Arial,sans-serif;
}
.PageHeaderButton-container {
  display: flex;
  height: 100%;
}
.PageHeader-Leftjustify {
  justify-content: left;
}
.PageHeaderDivider-pageHeaderDivider {
  flex-shrink: 0;
  margin: 15px 0;
  width: 2px;
  height: calc(100% - 30px);
  background-color: hsla(0,0%,0%,.08);
}
.MetadataListStylesMenu-stylesContainer {
  flex-shrink: 0;
  height: 100%;
}
.Slider-slider {
  position: relative;
  display: inline-block;
  min-width: 12px;
  min-height: 12px;
  width: 60px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.MetadataListStylesMenu-slider {
  margin-left: 12px;
  height: 100%;
}
.Slider-track {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  overflow: hidden;
  width: 100%;
  height: 2px;
  background-color: hsla(0,0%,100%,.2);
}
.Slider-thumbTrack {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  pointer-events: none;
}
.Slider-thumb {
  margin-left: -6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
  box-shadow: 0 0 2px rgba(0,0,0,.35);
  transition: left .1s,top .1s,opacity .2s,background-color .2s;
  pointer-events: auto;
}
.MetadataListStylesMenu-stylesButton {
  width: 50px;
  height: 100%;
  text-align: center;
}
.MetadataListStylesMenu-stylesButtonWithArrow {
  margin-left: 12px;
}
.PageContent-pageContent {
  position: relative;
  flex-grow: 1;
}
.PageContent-innerPageContent {
  padding: 60px;
  padding-top: 0;
  min-width: 100%;
  min-height: 100%;
}

.mobile-only {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@media only screen and (max-width: 768px)
{
  .QuickSearch-container {
      display: none;
  }
  .Page-mobile-open {
      flex-grow: 1;
      max-width: 100%;
      border-right: none;
      /* transition-property: all;
	    transition-duration: .5s;
	    transition-timing-function: cubic-bezier(0, 1, 0.5, 1); */
  }
  .Page-mobile-close {
      overflow-x: hidden;
      min-width: 0;
      max-width: 0;
  }
  
  .mobile-only {
    position: static;
    clip: auto;
    height: auto;
    width: auto;
    margin: 0;
    overflow: visible;
  }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
