 @font-face {
  font-family: fontstack;
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Regular"),local("Open Sans Semibold"),local("Helvetica Neue"),local("Helvetica"),local("Arial");
}

*, :after, :before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
    font-family: Open Sans Regular,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.71428571;
    background-color: transparent;
}
.application {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}
button, input, select, textarea {
  font-size: 100%;
  margin: 0;
}
button, input {
  line-height: normal;
}
button, select {
  text-transform: none;
}
button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}
button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button, input, select[multiple], textarea {
  background-image: none;
}
a {
  color: #4CAF50;
}
a, a:hover {
  text-decoration: none;
}
.background-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: #f3f2f1;
}
.scroll-container {
  position: relative;
  flex-grow: 1;
  padding: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.dark-scrollbar {
  scrollbar-face-color: #323232;
  scrollbar-arrow-color: #323232;
  scrollbar-track-color: #000;
  scrollbar-shadow-color: #000;
  scrollbar-highlight-color: #000;
  scrollbar-3dlight-color: #000;
  scrollbar-darkshadow-color: #000;
}
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib {
    display: none;
}
.mapboxgl-marker {
  left: -50%;
  right: -50%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border:1px solid gray;
  background-color:lightblue;
}
.control-panel {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;
}
#map-content {
    position: relative;
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    height: 100%;
    width: 50%;
}

/*** Icons ***/
.chevron {
  padding-right: 10px;
}
.chevron::before {
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
  content: '';
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  color: #000;
}
.chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}
.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}
.chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}